<template>

  <div>

    <b-card title="Add new column">

      <validation-observer ref="simpleRules">

        <b-form
          ref="addForm"
          @submit.prevent
        >
          <b-row>

            <b-col cols="6">
              <b-form-group
                label="Big Query Cloumn"
                label-for="v-bigQueryCloumn"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cloumn Name"
                  rules="required|alpha_dash"
                >
                  <b-form-input
                    id="v-bigQueryCloumn"
                    v-model="newColumn.bigQueryCloumn"
                    placeholder="Big Query Cloumn"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                  <span class="text-danger"> {{ errors[0] }} </span>

                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                label="Document Cloumn"
                label-for="v-documentCloumn"
              >
                <validation-provider
                  #default="{ errors }"
                  name="documentCloumn"
                  rules="required"
                >
                  <b-form-input
                    id="v-documentCloumn"
                    v-model="newColumn.documentCloumn"
                    placeholder="Document Cloumn"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                </validation-provider>

              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="loading"
                @click="saveColumn"
              >
                Add
              </b-button>

            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card-code
      no-body
      title="Columns"
    >

      <b-overlay
        :show="loading"
        rounded="sm"
      >

        <b-table
          striped
          hover
          :items="columns"
          :fields="fields"
        >

          <template #cell(id)="data">
            <span v-b-tooltip.hover.top="data.item.id">
              #
            </span>

          </template>

          <template #cell(createdAt)="data">
            {{ data.item.createdAt|formatDateTime }}
          </template>

          <template #cell(actions)="data">
            <router-link :to="{ name: 'edit-column', params: { columnId: data.item.id }}">
              <b-button

                variant="primary"
                size="sm"
                class="btn-tour-finish mr-1"
              >
                Edit
              </b-button>

            </router-link>

            <a
              class="text-danger"
              @click="remove(data.item.id)"
            >Remove</a>
          </template>

        </b-table>

        <div
          v-if="rows > 25"
          class="mt-1 d-flex justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            hide-goto-end-buttons
            :total-rows="rows"
            :per-page="perPage"
            @change="handlePageChange"
          />
        </div>

      </b-overlay>

    </b-card-code>

  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BRow, BCol, BFormGroup, BForm, BFormInput, BTable, BButton, VBTooltip, BPagination, BSpinner, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

import Ripple from 'vue-ripple-directive'
import {
  required,
  alpha_num,
  alpha_dash,
} from 'vee-validate/dist/rules'

extend('required', required)
extend('alpha_num', alpha_num)
extend('alpha_dash', alpha_dash)

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardCode,
    BTable,
    BButton,
    BPagination,
    BSpinner,
    ToastificationContent,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      newColumn: {},
      columns: [],
      templateId: null,
      fields: ['id', 'bigQueryCloumn', 'documentCloumn', 'createdAt', 'actions'],
      currentPage: 1,
      perPage: 50,
      lastPage: 0,
      rows: 0,
    }
  },

  created() {
    this.templateId = this.$route.params.templateId
    this.newColumn.documentTemplateId = this.templateId
    this.loadColumns({ templateId: this.templateId })
  },

  methods: {

    handlePageChange(value) {
      this.page = value

      this.loadColumns({ templateId: this.templateId, page: this.page })
    },

    loadColumns(params) {
      this.loading = true

      this.$store.dispatch('documentColumn/fetchAll', params)
        .then(result => {
          this.columns = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },

    remove(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger ',
          cancelButton: 'btn btn-outline-primary  ml-1',
        },
        buttonsStyling: false,

      }).then(result => {
        if (result.value === true) {
          this.loading = true

          this.$store.dispatch('documentColumn/remove', id).then(
            result => {
              this.loadColumns({ templateId: this.templateId, page: this.page })
            },
            error => {
              console.log(err)
            },
          ).finally(() => {
            this.loading = false
          })
        }
      },
      error => {
        console.log(err)
      })
    },
    saveColumn() {
      this.$refs.simpleRules.validate().then(valid => {
        if (valid) {
          this.loading = true

          this.$store.dispatch('documentColumn/create', this.newColumn)
            .then(() => {
              this.$refs.addForm.reset()
              this.showToast('Success', 'Column added successfully', 'success')
              this.loadColumns({ templateId: this.templateId, page: this.page })
            },
            error => {
              this.showToast('Error', error.message || 'Error while saving', 'danger')
            }).finally(() => {
              this.loading = false
            })
        } else {
          this.showToast('Form', 'Check your inputs please', 'warning')
        }
      }, error => {
        console.log(error)
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
